<template>
  <div ref="body">
    <custom-header active="service" :subActive="current"></custom-header>
    <!-- <div class="banner-container">
      <van-swipe ref="banner" :autoplay="5000" class="fill">
        <van-swipe-item v-for="(item, idx) in banners" :key="idx">
          <div class="fill" @click="urlLink(item.url)">
            <img :src="item.bannerPicture" alt="" style="width: 100%; height: auto;">
          </div>
        </van-swipe-item>
      </van-swipe>
    </div> -->
    <div class="content-container ">
      <h1>联系我们</h1>
      <div class="contact-box flex flex-wrap text-center ">
        <!-- <div class="item">商务洽谈: 巫小姐 13632776025</div> -->
        <div class="item">展商服务: 邢小姐 13686485032</div>
        <div class="item">观众服务: 骆女士 13534142651</div>
        <div class="item">媒体合作: 陈女士 18620388039</div>
        <div class="item">咨询与服务热线: 400 873 9129</div>
      </div>
      <div class="text-center">
        <h2>探索行业无限魅力 扫码获取前沿资讯</h2>
        <div class="flex justify-center flex-wrap">
          <div class="code">
            <img src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/cn/img/contact/1.%E6%B7%B1%E5%9C%B3%E5%AE%B6%E5%B1%85%E8%AE%BE%E8%AE%A1%E5%91%A8.jpg" class="block" />
            <span>深圳家居设计周</span>
          </div>
          <div class="code">
            <img src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/cn/img/contact/2.%E6%B7%B1%E5%9C%B3%E5%9B%BD%E9%99%85%E5%AE%B6%E5%85%B7%E5%B1%95.jpg" class="block" />
            <span>深圳国际家具展</span>
          </div>
          <div class="code">
            <img src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/cn/img/contact/3.SMART%E6%99%BA%E5%AE%B6%E5%B1%95.png" class="block" />
            <span>SMART智家展</span>
          </div>
          <div class="code">
            <img src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/cn/img/contact/4.Design%20Dome%E8%AE%BE%E8%AE%A1%E4%B8%87%E8%B1%A1.jpg" class="block" />
            <span>Design Dome设计万象</span>
          </div>
          <div class="code">
            <img src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/cn/img/contact/5.Muniverse%E4%B8%87%E6%9C%89%E6%9D%90%E6%96%99.jpg" class="block" />
            <span>Muniverse万有材料</span>
          </div>
          <div class="code">
            <img src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/cn/img/contact/6.%E8%AE%BE%E8%AE%A1%E5%BB%BA%E6%9D%90%2B.jpg" class="block" />
            <span>设计建材</span>
          </div>
          <div class="code">
            <img src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/cn/img/contact/7.%E5%9B%BD%E9%99%85%E5%85%AC%E5%BB%BA%E8%AE%BE%E8%AE%A1%E8%89%BA%E6%9C%AF%E5%B1%95.jpg" class="block" />
            <span>国际公建设计艺术展</span>
          </div>
          <div class="code">
            <img src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/cn/img/contact/8.%E7%9A%86%E5%8F%AF%E7%99%BE%E8%B4%A7.jpg" class="block" />
            <span>皆可百货</span>
          </div>
          <div class="code">
            <img src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/cn/img/contact/9.on%20light%E5%85%89%E5%B1%95.jpg" class="block" />
            <span>on light光展</span>
          </div>
          <div class="code">
            <img src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/cn/img/contact/10.%E8%AE%BE%E8%AE%A1%E5%B8%88%E4%BF%B1%E4%B9%90%E9%83%A8.jpg" class="block" />
            <span>设计师俱乐部</span>
          </div>
          <div class="code">
            <img src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/cn/img/contact/11.%E8%AE%BE%E8%AE%A1%E5%B7%A8%E8%9B%8B.jpg" class="block" />
            <span>设计巨蛋</span>
          </div>
          <div class="code">
            <img src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/cn/img/contact/12.%E5%AE%B6%E5%B1%85%E4%B8%A5%E9%80%89.jpg" class="block" />
            <span>家居严选</span>
          </div>
        </div>
      </div>
    </div>
    
    <custom-footer :friendLink="friendLink"></custom-footer>
    
    <!-- 回到顶部 -->
    <scroll-top @scrollTop="scrollTop"></scroll-top>
    
  </div>
</template>

<script>
import { Swipe, SwipeItem, Icon } from 'vant';
import customHeader from "./components/head.vue";
import customFooter from "./components/foot.vue";
import scrollTop from "./components/scrollTop.vue";
import commonMixin from './commonMixin.js';

export default {
  // name: '展会介绍',
  components: {
    'custom-header': customHeader,
    'custom-footer': customFooter, 'scroll-top': scrollTop,
    [Icon.name]: Icon,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
  },
  mixins: [commonMixin],
  metaInfo: {
    // title: 'My Example App', // set a title
    meta: [
      { name: 'keyWords', content: '深圳家具展，家具展，家居设计，家具展商，家居论坛，设计周，家居设计周' },
      { name: 'description', content: '深圳家居设计周暨40届深圳国际家具展，将于2025年3月16-19日在深圳国际会展中心盛大启幕，是目前国内唯一横向打通住宅、建筑、室内、家具、软装、灯光及工作环境全产业链的产业创新与教育分享展。' },
    ],
  },
  data() {
    return {
      current: 'contact',
    }
  },
  created() {

  },
  methods: {

  },
}
</script>

<style scoped lang="less">

@media screen and (min-width: 1024px) {
  .banner-container {
    width: 100%;
    
    .banner {
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% auto;
    }
  }
  .content-container {
    width: 280px;
    margin: 20px auto ;
    
    h1 {
      text-align: center;
      font-size: 6px;
      margin-bottom: 8px;
    }
    
    .contact-box {
      margin-bottom: 10px;
      
      .item {
        width: 50%;
        margin-bottom: 5px;
        font-size: 5px;
      }
    }
    
    h2 {
      font-size: 6px;
      margin-bottom: 8px;
    }
    
    .code {
      width: 60px;
      margin: 5px;
      padding: 3px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, .2);
      text-align: center;
      font-size: 3.4px;
      border-radius: 1px;
      
      img {
        width: 100%;
        height: auto;
      }
    }
  }
}

// 移动端
@media screen and (max-width: 1023px) {
  .banner-container {
    width: 100%;
    
    .banner {
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% auto;
    }
  }
  .content-container {
    width: 360px;
    margin: 20px auto ;
    
    h1 {
      text-align: center;
      font-size: 24px;
      margin-bottom: 20px;
    }
    
    .contact-box {
      margin-bottom: 30px;
      
      .item {
        width: 100%;
        margin-bottom: 5px;
        font-size: 12px;
        padding-left: 20px;
      }
    }
    
    h2 {
      font-size: 16px;
      padding: 0 10px;
      margin-bottom: 8px;
    }
    
    .code {
      width: 155px;
      margin: 10px;
      padding: 15px 0;
      box-shadow: 0 2px 6px rgba(0, 0, 0, .2);
      text-align: center;
      font-size: 13px;
      // line-height: 3em;
      border-radius: 4px;
      
      img {
        width: 100%;
        height: auto;
      }
    }
  }
}
  
</style>